.m-image-block {
  margin-top: 1.4rem;
  margin-bottom: 1.4rem;
  clear: both;

  &::after {
    display: block;
    clear: both;
    content: '';
  }

  &__figure {
    width: 100%;
    padding-bottom: 1.4rem;
    margin-right: auto;
    margin-left: auto;

    @include respond($break50) {
      max-width: 220px;
    }

    @include respond($break60) {
      max-width: 285px;
    }

    &--left {
      @include respond($break50) {
        float: left;
        margin-right: 30px;
      }
    }

    &--right {
      @include respond($break50) {
        float: right;
        margin-left: 30px;
      }
    }

    &--small {
      max-width: 175px;
    }
  }

  &__image {
    width: 100%;
    height: auto;
  }

  &__figcaption {
    @include copyCaption;

    color: $neutral70;
  }
}
