.header-navigation {
  position: absolute;
  top: 5px;
  right: $containerPadding;
  display: flex;

  @include respond($break60) {
    top: 0;
    flex-direction: column;
  }

  @include respond($break100) {
    right: $containerPaddingMax;
  }

  button,
  > a {
    @include buttonUnstyled;

    padding: 0;
    font-size: 14px;
    line-height: 1.429;
    color: $neutral40;
    text-align: right;

    @include respond($break60) {
      padding: 0;
    }

    &::after {
      width: 15px;
      margin-left: 6px;
      color: $accent;
      text-align: center;
      vertical-align: middle;
    }

    + button,
    + a {
      margin-left: 21px;

      @include respond($break60) {
        margin-left: 0;
      }
    }

    &.explore {
      @include iconPost($plus);

      @include respond($break60) {
        order: 1;
      }
    }

    &.search {
      @include iconPost($magnify);

      @include respond($break60) {
        order: 2;
        margin-top: 6px;
      }

      &::after {
        font-size: 13px;
      }
    }

    &.apply {
      @include iconPost($check);

      @include respond($break60) {
        order: 3;
        margin-top: 6px;
      }

      span {
        display: none;

        @include respond($break60) {
          display: inline;
        }
      }

      &::after {
        font-size: 10px;
      }
    }
  }
}
