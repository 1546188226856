// Shared Components Manifest
// --------------------------
.content-section {
  // Default paddings
  padding-top: 40px;
  padding-bottom: 40px;

  @include respond($break50) {
    padding-top: 7.368vw;
    padding-bottom: 7.368vw;
  }

  @include respond($break100) {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  &.flush-top:not(.bg-accent10, .bg-accent20, .bg-accent30) {
    padding-top: 0;
  }
}

.section-header {
  padding-bottom: 30px;

  .section-title {
    @include headingPrimary;

    color: $accent;
  }
}

// Featured icon used in all sections
.featured-icon {
  width: 35px;
  margin-bottom: 12px;

  img {
    max-width: 100%;
    height: auto;
  }
}

.content-post-single {
  padding-bottom: 94px;

  .post-external .box-link {
    margin-top: 18px;
  }
}

.post-meta {
  @include listUnstyled;

  padding-top: 24px;

  li {
    font-size: 12px;
    color: $neutral50;
    text-transform: uppercase;

    + li {
      margin-top: 2px;
    }
  }
}

// <nav> with nested <ul>
// For navigating terms within a single page
.term-nav {
  // This might need to be abstracted for use areas
  padding-top: 17px;

  ul {
    @include listUnstyled;
    @include iconPre($pin);

    display: inline-block;

    &::before {
      font-size: 10px;
      vertical-align: middle;
    }

    li {
      display: inline-block;
    }

    a {
      @include labelPrimary;

      font-size: 12px;
      color: $neutral40;
    }
  }
}

// A multi 5 column term list used on index pages
.term-list-primary {
  padding-top: 58px;
  padding-bottom: 96px;

  ul {
    @include termList;

    display: flex;
    flex-wrap: wrap;
    margin-bottom: -24px;
    margin-left: -$gridPadding;

    @include respond($break100) {
      margin-left: -$gridPaddingMax;
    }

    li {
      width: calc(50% - #{$gridPadding});
      margin-bottom: 24px;
      margin-left: $gridPadding;

      @include respond($break60) {
        width: calc(20% - #{$gridPadding});
      }

      @include respond($break100) {
        width: calc(20% - #{$gridPaddingMax});
        margin-left: $gridPaddingMax;
      }

      &.active a {
        color: $neutralOffBlack;
        border-color: $accent;
      }
    }
  }
}

.term-list-secondary {
  padding-top: 52px;

  @include respond($break60) {
    padding-bottom: 29px;
  }

  ul {
    @include termList;

    display: flex;
    flex-wrap: wrap;

    @include respond($break60) {
      margin-left: -$gridPadding;
    }

    @include respond($break100) {
      margin-left: -$gridPaddingMax;
    }

    li {
      width: 100%;

      @include respond($break60) {
        width: calc(33.333% - #{$gridPadding});
        margin-left: $gridPadding;
      }

      @include respond($break100) {
        width: calc(33.333% - #{$gridPaddingMax});
        margin-left: $gridPaddingMax;
      }

      &.active a {
        color: $neutralOffBlack;
        border-color: $accent;
      }

      + li {
        margin-top: 26px;

        @include respond($break60) {
          margin-top: 0;
          margin-bottom: 24px;
        }
      }
    }
  }
}

.section-tabs {
  padding-bottom: 71px;

  .tab {
    // Hidden by default
    height: 0;
    overflow: hidden;
    filter: blur(10px);
    opacity: 0;
    transition:
      opacity $duration - 0.1 $easeOutCubic 0.1s,
      filter $duration - 0.1 $easeOutCubic 0.1s;

    &.active {
      height: auto;
      overflow: visible;
      filter: blur(0);
      opacity: 1;
      transition:
        opacity $duration - 0.1 $easeOutCubic,
        filter $duration - 0.1 $easeOutCubic;
    }

    .lead {
      padding-top: 22px;
      font-size: 16px;
      line-height: 1.563;
    }
  }

  nav {
    padding-top: 90px;
    padding-bottom: 33px;

    ul {
      @include listUnstyled;

      display: flex;
      flex-direction: column;

      @include respond($break60) {
        flex-direction: row;
        margin-left: -$gridPadding;
      }

      @include respond($break100) {
        margin-left: -$gridPaddingMax;
      }

      li {
        width: 100%;

        @include respond($break60) {
          width: calc(33.333% - #{$gridPadding});
          margin-left: $gridPadding;
        }

        @include respond($break100) {
          width: calc(33.333% - #{$gridPaddingMax});
          margin-left: $gridPaddingMax;
        }

        + li {
          padding-top: 12px;

          @include respond($break60) {
            padding-top: 0;
          }
        }
      }
    }

    button {
      @include buttonUnstyled;
      @include labelPrimary;

      width: 100%;
      padding-top: 11px;
      font-size: 12px;
      color: $neutral40;
      text-align: left;
      border-top: 1px solid $neutral30;

      @include respond($break70) {
        font-size: 14px;
      }

      &.active {
        color: $neutralOffBlack;
        border-color: $accent;
      }
    }
  }
}
