// List of <articles> with class "tease"
// This is a generic post list used for blogs and stories
.post-list {
  padding-bottom: 44px;

  .tease {
    padding-bottom: 55px;

    + .tease {
      padding-top: 42px;
      border-top: 1px solid $neutral30;
    }

    .arrow-link {
      display: block;
      padding-top: 30px;
      color: $neutralOffBlack;

      &:hover {
        color: $accent;
      }

      &.flush {
        padding-top: 5px;
      }

      + .arrow-link {
        padding-top: 0;
      }
    }
  }
}

.post-list-aside {
  // Usually an <a>
  // stylelint-disable-next-line no-descending-specificity
  .tease {
    padding-bottom: 43px;
    color: inherit;

    &:hover {
      color: $accent;

      .rte-content {
        color: $accent;
      }

      .arrow-link {
        color: $accent;
      }
    }

    + .tease {
      padding-top: 38px;
      border-top: 1px solid $neutral30;
    }
  }
}
