// Post type header, used on lists and single pages
.type-header {
  position: relative;
  padding-bottom: 6px;
  margin-bottom: 25px;
  border-bottom: 1px solid $neutral30;

  .icon {
    width: 40px;

    img {
      max-width: 100%;
    }
  }

  .title {
    @include headingPrimary;

    color: $accent;

    a {
      color: $accent;

      &:hover {
        color: $neutralOffBlack;
      }
    }
  }

  .type-index {
    position: absolute;
    top: 0;
    right: 0;

    @include respond($break70) {
      top: auto;
      bottom: 4px;
    }

    .index-trigger {
      @include buttonUnstyled;
      @include labelPrimary;
      @include iconPre($pin);

      bottom: 5px;
      color: $neutral40;

      &::before {
        margin-right: 11px;
        font-size: 11px;
        vertical-align: middle;
      }
    }

    .index-dropdown {
      position: absolute;
      top: 41px;
      left: 50%;
      width: 200px;
      padding: 20px 10px 25px;
      margin-left: -100px;
      text-align: center;
      visibility: hidden;
      background-color: $accent20;
      filter: blur(10px);
      opacity: 0;
      transition:
        opacity $duration - 0.1 $easeOutCubic,
        filter $duration - 0.1 $easeOutCubic,
        visibility 0s linear $duration,
        transform $duration $easeOutCubic;
      transform: scale(1.07, 1.07);

      &.open {
        visibility: visible;
        filter: blur(0);
        opacity: 1;
        transition:
          opacity $duration - 0.1 $easeOutCubic,
          filter $duration - 0.1 $easeOutCubic,
          visibility 0s linear,
          transform $duration $easeOutCubic;
        transform: scale(1, 1);
      }

      &::before {
        position: absolute;
        top: -16px;
        left: 50%;
        width: 31px;
        height: 16px;
        margin-left: -15px;
        content: '';
        border-color: transparent transparent $accent20;
        border-style: solid;
        border-width: 0 15.5px 16px;
      }

      ul {
        @include listUnstyled;
      }

      li + li {
        margin-top: 5px;
      }

      li a {
        @include labelPrimary;

        line-height: 1.916;
        color: $neutralOffBlack;

        &:hover {
          color: $accent;
        }
      }
    }
  }
}
