@use 'sass:color';

// Standard modal used with a number of components
.modal {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  padding-right: $containerPadding;
  padding-left: $containerPadding;
  overflow: auto;
  visibility: hidden;
  background-color: color.adjust($neutral70, $alpha: -0.3);
  filter: blur(10px);
  opacity: 0;
  transition:
    opacity $duration - 0.1 $easeOutCubic 0.1s,
    visibility 0s linear $duration,
    filter $duration - 0.1 $easeOutCubic 0.1s;

  @include respond($break100) {
    padding-right: $containerPaddingMax;
    padding-left: $containerPaddingMax;
  }

  .container {
    align-self: center;
    width: auto;
    max-width: 660px;
    padding: 40px 0;
    margin: auto;
    transition: transform $duration $easeOutCubic;
    transform: scale(1.07, 1.07);
  }

  &.open {
    visibility: visible;
    filter: blur(0);
    opacity: 1;
    transition:
      opacity $duration - 0.1 $easeOutCubic,
      visibility 0s linear,
      filter $duration - 0.1 $easeOutCubic;

    .container {
      transform: scale(1, 1);
    }
  }

  header {
    position: relative;
    display: flex;
    align-items: center;
    height: 112px;
    background-color: $accent10;
  }

  figure {
    width: 112px;
    height: 112px;
    background-position: 50% 50%;
    background-size: cover;
  }

  .title {
    padding-bottom: 4px;

    // VW version of container padding to share between elements
    // That don't have the same "100%"
    padding-left: 5.479vw;
    font-size: 18px;
    color: $neutralOffBlack;

    @include respond($break70) {
      padding-left: 46px;
    }
  }

  .subtitle {
    @include copyPrimary;

    display: block;
    padding-left: 5.479vw;
    color: $neutral40;

    @include respond($break70) {
      padding-left: 46px;
    }
  }

  .modal-close {
    @include buttonUnstyled;
    @include iconPre($x);

    position: absolute;
    top: 24px;
    right: 22px;
  }

  .content {
    @include copyPrimary;

    padding: 5.479vw;
    background-color: $accent20;

    @include respond($break70) {
      padding: 42px 46px;
    }

    a {
      font-weight: $semibold;
    }
  }
}
