// Unicode range values!
// Order is alphabetical.
// Icons are stored in a SASS map, so adding one automatically generates
// a placeholder for using it (see below)
// --------------------------------------------------------
$arrowBoxDown: '\f101';
$arrowBoxRight: '\f102';
$arrowLongRight: '\f103';
$arrowShortDown: '\f104';
$arrowShortLeft: '\f105';
$arrowShortRight: '\f106';
$caretLeft: '\f107';
$caretRight: '\f108';
$check: '\f109';
$document: '\f10a';
$documentPencil: '\f10b';
$logo: '\f10c';
$logoOutlined: '\f10d';
$magnify: '\f10e';
$pin: '\f10f';
$plus: '\f110';
$x: '\f111';


// Icon font
// --------------------------------------------------------
// This font was generated using https://www.npmjs.com/package/webfonts-generator
// and plain old CIC ingenuity
@font-face {
  font-family: sloan-icons;
  font-style: normal;
  font-weight: normal;
  src:
    url('../fonts/sloan-icons.woff') format('woff'),
}

@mixin fontIcon {
  font-family: sloan-icons, sans-serif;
}
