.header-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100%;
  padding: 7.368vw 0 76px;
  visibility: hidden;
  filter: blur(10px);
  opacity: 0;
  transition:
    opacity $duration - 0.1 $easeOutCubic 0.1s,
    visibility 0s linear $duration,
    filter $duration - 0.1 $easeOutCubic 0.1s;

  @include respond($break100) {
    padding-top: 70px;
  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    background-color: $accent20;
  }

  &.open {
    visibility: visible;
    filter: blur(0);

    // Add transition on border for focus trap
    border-bottom: 1px solid transparent;
    opacity: 1;
    transition:
      opacity $duration - 0.1 $easeOutCubic,
      visibility 0s linear,
      filter $duration - 0.1 $easeOutCubic,
      border 1ms linear;

    &:focus-within {
      border-bottom: 2px solid transparent;
    }
  }

  .container {
    position: relative;
  }

  .overlay-background {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100vw;
    height: 100vh;
    background-color: $neutral70;
    opacity: 0.7;
  }

  .overlay-close {
    @include buttonUnstyled;
    @include copyPrimary;
    @include iconPost($x);

    width: 100%;
    color: $neutral70;
    text-align: right;

    @include respond($break70) {
      position: absolute;
      top: 0;
      right: $containerPadding;
      width: auto;
    }

    @include respond($break100) {
      right: 0;
    }

    &::after {
      margin-left: 10px;
      font-size: 10px;
      vertical-align: middle;
    }
  }
}
