// Section wrapping a container and a <ul>
.featured-news {
  overflow: hidden;

  ul {
    @include listUnstyled;

    display: flex;
    padding-top: 13px;
    padding-bottom: 12px;
    transition: transform $durationSlow $easeOutCubic;

    @include respond($break80) {
      width: 100%;
      max-width: $containerWidth;
      padding-right: $containerPadding;
      padding-left: $containerPadding;
      margin: 0 auto;
    }

    @include respond($break100) {
      padding-right: $containerPaddingMax;
      padding-left: $containerPaddingMax;
    }

    &.count-2 {
      width: 300vw;
    }

    &.count-3 {
      width: 400vw;
    }

    &.count-2,
    &.count-3 {
      @include respond($break80) {
        width: 100%;
      }
    }
  }

  li {
    display: block;
    width: 100%;

    @include respond($break80) {
      width: calc(25% - #{$gridPadding});
      margin-left: $gridPadding;
    }

    &:first-child {
      @include respond($break80) {
        margin-left: 0;
      }
    }

    a {
      @include copyPrimary;

      display: block;
      padding-right: $containerPadding;
      padding-left: $containerPadding;
      color: $neutral70;

      @include respond($break80) {
        padding-top: 21px;
        padding-right: 0;
        padding-left: 0;
        border-top: 1px solid $neutral70;
      }

      &:hover {
        color: $accent;
        border-color: $accent;
      }

      .link-indicator {
        @include iconPre($arrowBoxRight);

        display: none;
        margin-top: 11px;
        transition: margin $duration $easeOutCubic;

        @include respond($break80) {
          display: block;
        }
      }

      &:hover .link-indicator {
        margin-left: 8px;
      }
    }

    &:last-child a {
      border-color: $neutral70;
    }
  }

  .all-link {
    a {
      display: block;
      border-top: 1px solid $neutral40;

      &:hover {
        border-color: $accent;
      }
    }

    &.grid {
      display: none;

      @include respond($break80) {
        display: block;
      }
    }

    &.full {
      margin-top: 46px;
      clear: both;

      @include respond($break80) {
        display: none;
      }
    }
  }

  .page-left,
  .page-right {
    @include buttonUnstyled;

    font-size: 8px;

    @include respond($break80) {
      display: none;
    }
  }

  .page-left {
    @include iconPre($arrowShortLeft);
  }

  .page-right {
    @include iconPre($arrowShortRight);

    float: right;
  }
}
