// <nav> wrapping a <ul>
.scholars-list {
  > header {
    padding-bottom: 31px;

    .category,
    .title {
      @include headingPrimary;
    }

    .title {
      color: $accent;
    }
  }

  ul {
    @include grantsList;

    display: flex;
    flex-wrap: wrap;

    @include respond($break60) {
      margin-left: -$gridPadding;
    }

    @include respond($break100) {
      margin-left: -$gridPaddingMax;
    }

    li {
      width: 100%;

      @include respond($break60) {
        width: calc(33.333% - #{$gridPadding});
        margin-left: #{$gridPadding};
      }

      @include respond($break100) {
        width: calc(33.333% - #{$gridPaddingMax});
        margin-left: $gridPaddingMax;
      }
    }
  }

  .arrow-link {
    color: $neutralOffBlack;

    &:hover {
      color: $accent;
    }
  }

  &.all-years {
    ul {
      li {
        margin-bottom: 19px;

        .title {
          padding-top: 20px;
          font-size: 16px;
        }
      }
    }
  }
}

.scholars-nav {
  margin-bottom: 36px;

  .nav-container {
    display: flex;
    flex-wrap: wrap;
    margin-left: -18px;
  }

  .year-anchor {
    @include labelPrimary;

    display: block;
    flex-grow: 1;
    width: 64px;
    max-width: 64px;
    padding-top: 0;
    padding-bottom: 11px;
    margin-left: 18px;
    color: $neutral40;
    border-top: 1px solid $neutral30;

    &:hover {
      color: $accent;
      border-color: $accent;
    }
  }
}
