.hamburger-navigation {
  transition: transform $duration $easeOutCubic;
  transform: scale(1.07, 1.07);

  .open & {
    transform: scale(1, 1);
  }

  .navigation-primary {
    @include listUnstyled;

    column-gap: 0;
    max-width: 400px;
    padding-top: 16px;
    padding-bottom: 36px;
    font-size: 16px;
    line-height: 2.167;
    column-count: 2;

    @include respond($break70) {
      column-gap: 1em;
      max-width: 450px;
      padding-top: 0;
      font-size: 24px;
    }

    a {
      color: $neutralOffBlack;
    }
  }

  .navigation-secondary {
    @include listHorizontal;

    a {
      font-size: 14px;
      font-weight: $regular;
      color: $neutral40;

      @include respond($break70) {
        font-size: 18px;
      }
    }
  }
}
