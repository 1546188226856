// <ul>
.person-list-container {
  padding-top: 31px;

  @include respond($break60) {
    display: flex;
  }
}

.person-list {
  @include listUnstyled;

  @include respond($break60) {
    column-count: 3;
    column-gap: $gridPadding;
  }

  @include respond($break100) {
    column-gap: $gridPaddingMax;
  }

  // With child person lists are only two-columns, and leave
  // room for a third one next to them
  &.with-child {
    flex-grow: 2;

    @include respond($break60) {
      column-gap: $gridPadding;
      padding-right: $gridPadding;
      column-count: 2;
    }

    @include respond($break100) {
      column-gap: $gridPaddingMax;
      padding-right: $gridPaddingMax;
    }
  }

  li {
    @include copyPrimary;

    break-inside: avoid;


    // Use pseudo elements instead of margins
    // to prevent breaking on margins
    &::after {
      display: block;
      height: 25px;
      content: '';
    }

    .name {
      display: block;
      font-weight: $semibold;
      color: $neutralOffBlack;
      transition: color $duration $easeOutCubic;
    }

    .person-title {
      display: block;
      color: $neutral40;
    }

    a {
      color: $accent;
    }

    button {
      @include buttonUnstyled;

      text-align: left; // OD

      &:hover {
        .name {
          color: $accent;
        }
      }

      .name {
        &::after {
          display: inline-block;
          margin-left: 0.6em;
          content: '+';
        }
      }
    }
  }
}

// Simplified secondary <ul>
.person-child-list {
  @include listUnstyled;

  width: 100%;

  @include respond($break60) {
    width: calc(33.333% - #{$gridPadding});
  }

  @include respond($break100) {
    width: calc(33.333% - #{$gridPaddingMax});
  }

  .group {
    font-weight: $semibold;
    color: $accent;
  }

  li {
    @include copyPrimary;

    + li {
      margin-top: 8px;
    }
  }

  .name {
    display: block;
    font-weight: $semibold;
    color: $neutralOffBlack;
    transition: color $duration $easeOutCubic;
  }
}
