// Content
// -------

// Defaults for RTE content
// --------------------------------------------------------
.rte-content {
  @include copyRead;

  a {
    font-weight: $semibold;
    color: $neutralOffBlack;

    &:hover {
      color: $accent;
    }

    &.button-primary {
      color: $neutralWhite;
    }
  }

  .button-primary {
    display: inline-block;
    max-width: 100%;
  }

  // WordPress Default classes
  // --------------------------------------------------------
  .alignright,
  .alignleft,
  .aligncenter {
    display: block;
    float: none;
    text-align: center;
  }

  .alignright {
    @include respond($break60) {
      float: right;
      margin-left: 30px;
    }
  }

  .alignleft {
    @include respond($break60) {
      float: left;
      margin-right: 30px;
    }
  }

  .aligncenter {
    margin: 0 auto;
  }

  .wp-caption {
    // Override WordPress inline image sizing
    max-width: 100%;
    padding-bottom: $verticalSpace * 1.8;

    // Force centered, full width captions on mobile
    @include respond($break60, max) {
      /* stylelint-disable-next-line declaration-no-important */
      width: 100% !important;
    }

    .wp-caption-text {
      padding-top: 13px;
      font-size: 12px;
      color: $neutral70;
      text-transform: uppercase;

      @include respond($break60) {
        text-align: left;
      }
    }
  }

  // WordPress 5.9 changed how image blocks are rendered.
  // Need to keep the above code to style older image blocks, and figcaption code below to style new posts.
  .wp-block-image,
  .wp-block-image figure {
    // Override WordPress inline image sizing
    max-width: 100%;

    // Force centered, full width captions on mobile
    @include respond($break60, max) {
      /* stylelint-disable-next-line declaration-no-important */
      width: 100% !important;
    }

    figcaption {
      padding-top: 13px;
      font-size: 12px;
      color: $neutral70;
      text-align: center;
      text-transform: uppercase;

      @include respond($break60) {
        text-align: left;
      }
    }
  }

  p + .wp-caption,
  ul + .wp-caption,
  ol + .wp-caption,
  blockquote + .wp-caption,
  .wp-caption + .wp-caption {
    padding-top: $verticalSpace * 1.4;

    @include respond($break60) {
      // Give itself and the
      padding-top: $verticalSpace;

      + p,
      + ul,
      + ol,
      + blockquote {
        padding-top: $verticalSpace;
      }
    }
  }

  img {
    max-width: 100%;
    height: auto;
    margin: 0 auto;

    &.alignright,
    &.alignleft {
      padding-bottom: $verticalSpace;
    }
  }

  // Basic table styles
  table {
    width: 100%;
    margin-bottom: 2em;
    color: $neutral40;

    tr td {
      width: 20%;
      padding-top: 5px;
      padding-right: 1.25em;
      padding-bottom: 5px;

      &.wide {
        width: 40%;

        @include respond($break60) {
          width: 50%;
        }
      }
    }

    tr:nth-child(even) {
      td {
        background-color: $accent10;
      }
    }
  }

  // FluidVids class is wrapped around video embeds automatically
  .fluid-vids {
    margin: $verticalSpace 0;

    @include respond($break60) {
      margin: $verticalSpace * 1.4 0;
    }
  }

  // Flud vids can be wrapped in left or right floating wrapper
  .video-float-right {
    // Keep p margins
    margin: $verticalSpace 0 0;

    + p,
    + ul,
    + ol,
    + blockquote {
      padding-top: $verticalSpace;
    }

    @include respond($break60) {
      float: right;
      width: 50%;
      margin: $verticalSpace 0 $verticalSpace 1.2em;
    }

    .fluid-vids {
      margin: 0;
    }
  }

  .video-float-left {
    @include respond($break60) {
      float: left;
      width: 50%;
      margin: $verticalSpace * 1.4 1.2em $verticalSpace 0;
    }

    .fluid-vids {
      @include respond($break60) {
        margin: 0;
      }
    }
  }

  h1,
  h2 {
    @include headingPrimary;

    padding-bottom: 30px;
    color: $accent;
  }

  h3,
  h4,
  h5,
  h6 {
    @include headingTertiary;

    padding-bottom: 9px;
    color: $neutralOffBlack;
  }

  strong {
    font-weight: $regular;
    color: $accent;

    &.neutral {
      font-weight: $semibold;
      color: $neutral70;
    }
  }

  .lead {
    @include leadPrimary;

    a {
      font-weight: $semibold;
      color: $neutralOffBlack;

      &:hover {
        color: $accent;
      }
    }

    + .lead,
    + p,
    + ul,
    + ol,
    + blockquote + .wp-caption {
      margin-top: 40px;
    }
  }

  .subtitle {
    @include copyPrimary;

    font-size: 18px;
    color: $neutral40;
  }

  // a <blockquote wrapping multiple p tags>
  .columnar {
    // Override blockquote defaults
    max-width: none;
    margin: 0;
    font-size: inherit;
    color: inherit;
    text-align: left;

    a {
      font-weight: $semibold;
    }

    @include respond($break60) {
      columns: 2;
      column-gap: 6.316vw;
    }

    @include respond($break100) {
      column-gap: 60px;
    }
  }

  // Tag on a top level <ul>
  .faq-list {
    @include listUnstyled;
    @include copyPrimary;

    // Top level li's only
    > li {
      padding: 27px 0 26px;
      cursor: pointer;

      &:hover {
        color: $accent;
      }

      + li {
        border-top: 1px solid $neutral40;
      }

      &.open {
        .toggle-indicator::before {
          content: '-';
        }

        > ul {
          max-height: 9000px;
          overflow: visible;
          transition: max-height 0s linear 0s;
        }

        // Required to prevent over-nesting
        // scss-lint:disable MergeableSelector
        > ul li {
          filter: blur(0);
          opacity: 1;
          transform: scale(1, 1);
        }
      }
    }

    .toggle-indicator {
      display: inline-block;
      padding-left: 13px;

      &::before {
        font-style: normal;
        content: '+';
      }
    }

    // Answers
    ul {
      @include listUnstyled;

      max-height: 0;
      overflow: hidden;
      transition: max-height 0s linear $duration - 0.1;

      // Nested answer block (can be padded and such)
      li {
        padding-top: 26px;
        color: $neutral40;
        filter: blur(10px);
        opacity: 0;
        transition:
          opacity $duration - 0.1 $easeOutCubic 0.1s,
          filter $duration - 0.1 $easeOutCubic 0.1s,
          transform $duration - 0.1 $easeOutCubic 0.1s;
        transform: scale(1.07, 1.07);
      }
    }

    // This class is applied via JS is a ul is used within an
    // FAQ answer
    .nested-list {
      max-height: none;
      padding-bottom: 26px;
      padding-left: 30px;
      list-style-type: disc;

      li + li {
        padding-top: 16px; // OD
      }
    }

    table {
      // Remove margins on nested tables
      margin-bottom: 0;
    }
  }

  // Link list with icons
  .icon-arrow-list {
    @include listUnstyled;

    display: flex;
    flex-direction: column;
    padding: 27px 0 30px;

    @include respond($break60) {
      flex-direction: row;
      padding-bottom: 50px;
      margin-left: -$gridPadding;
    }

    @include respond($break100) {
      margin-left: -$gridPaddingMax;
    }

    li {
      width: 100%;
      border: 1px solid $neutral70;

      @include respond($break60) {
        width: calc(33.333% - #{$gridPadding});
        margin-left: $gridPadding;
      }

      @include respond($break100) {
        width: calc(33.333% - #{$gridPaddingMax});
        margin-left: $gridPaddingMax;
      }

      .bg-accent20 & {
        background-color: $neutralWhite;
        border-color: transparent;
      }

      + li {
        margin-top: 20px;

        @include respond($break60) {
          margin-top: 0;
        }
      }
    }

    a {
      @include iconPost($arrowLongRight);

      display: block;
      padding: 19px 10px;
      color: $neutralOffBlack;
      text-align: center;

      &:hover {
        color: $accent;
      }

      &::after {
        display: block;
        padding-top: 15px;
        font-size: 7px;
      }

      img {
        display: block;
        max-width: 50px;
        padding-bottom: 17px;
        margin: 0 auto;
      }
    }
  }

  // Blockquotes
  // --------------------------------------------------------
  blockquote {
    display: block;
    max-width: 470px;
    padding: 33px 0 28px;
    margin: 0 auto;
    font-size: 20px;
    line-height: 1.35;
    color: $accent;
    text-align: center;


    @include respond($break50) {
      padding: 49px 0 44px;
    }

    .byline {
      display: block;
      padding-top: 18px;
      font-size: 15px;
      color: $neutral40;

      &::before {
        padding-right: 6px;
        content: '-';
      }
    }
  }

  .quote-secondary {
    @include copyPrimary;

    max-width: 100%;
    padding: 0 0 0 21px;
    margin: 33px 0 35px;
    color: $neutral50;
    text-align: left;
    border-left: 1px solid $accent;

    @include respond($break50) {
      margin: 46px 0 48px;
    }
  }
}
