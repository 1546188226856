// Both report pre-footers
// -----------------------

.report-pre-footer-single {
  text-align: center;

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 33px;

    @include respond($break50) {
      flex-direction: row;
      text-align: left;
    }
  }

  .icon-document {
    @include iconPost($logo);

    position: relative;
    margin-right: 25px;
    color: $neutralOffBlack;

    &:hover + div h4 {
      color: $accent;
    }

    &::before {
      display: inline-block;
      width: 71px;
      height: 94px;
      content: '';
      background-image: url('../images/icon_document.png');
      background-repeat: no-repeat;

      // Using a png here to get around a chunky export
      // by our icon-font generator
      background-size: contain;
    }

    &::after {
      position: absolute;
      top: 26px;
      left: 20px;
      display: block;
      font-size: 36px;
    }
  }

  // <a>
  h4 {
    display: inline-block;
    padding-bottom: 2px;
    color: $neutral70;
    transition: color $duration $easeOutCubic;

    @include respond($break50) {
      padding-bottom: 6px;
      border-bottom: 1px solid $neutralOffBlack;
    }

    &:hover {
      color: $accent;
    }
  }

  .arrow-link {
    @include labelPrimary;

    display: block;
    padding-top: 4px;
    color: $neutral40;

    &::before {
      margin-bottom: 3px; // NP
      font-size: 7px;
    }
  }
}


.report-pre-footer-all {
  .lead {
    margin-bottom: 34px;
    font-size: 16px;
    line-height: 1.563;
  }

  .report-current {
    font-size: 18px;
    color: $neutralOffBlack;


    @include respond($break50) {
      font-size: 20px;
    }

    &:hover {
      color: $accent;

      .icon-document {
        color: $neutralOffBlack;
      }
    }

    .icon-document {
      @include iconPre($document);
      @include iconPost($arrowBoxDown);

      position: relative;
      display: inline-block;
      margin-right: 8px;
      font-size: 58px;
      vertical-align: middle;

      @include respond($break50) {
        margin-right: 18px;
        font-size: 80px;
      }

      &::after {
        position: absolute;
        top: 1.3em;
        left: 0.783em;
        font-size: 0.288em;
      }
    }
  }

  .report-list {
    @include listUnstyled;

    padding-top: 23px;

    @include respond($break60) {
      column-count: 2;
      column-gap: 30px;
      padding-top: 36px;
    }

    li {
      margin-bottom: 21px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    a {
      @include iconPre($arrowBoxDown);

      display: block;
      font-size: 16px;
      color: $neutralOffBlack;

      &:hover {
        color: $accent;
      }

      &::before {
        display: inline-block;
        margin-right: 12px;
        margin-bottom: 2px;
        font-size: 23px;
        vertical-align: bottom;
      }
    }
  }
}
