.site-header {
  position: relative;
  padding: 7.368vw 0 88px;

  @include respond($break100) {
    padding-top: 70px;
  }

  .container {
    position: relative;
  }

  .logo {
    @include logo;
    @include iconPre($logo);

    display: inline-block;
    margin-left: -5px;

    &::before {
      display: inline-block;
      font-size: 31px;
      color: $accent;
      text-align: center;

      @include respond($break60) {
        height: 68px;
        padding-top: 14px;
        margin-right: 13px;
        font-size: 39px;
        color: $accent;
        vertical-align: middle;
      }
    }

    .site-title,
    .page-title {
      display: inline-block;
      vertical-align: middle;
    }

    .site-title {
      display: none;
      padding-top: 1px; // NP
      color: $accent;

      @include respond($break60) {
        display: inline-block;
      }

      span {
        color: $neutral40;
      }
    }

    .page-title {
      display: none;
    }
  }
}
