// <nav> wrapping a <ul>
.grants-list {
  > header {
    padding-bottom: 31px;

    .category,
    .title {
      @include headingPrimary;
    }

    .title {
      color: $accent;
    }

    p {
      @include copyPrimary;

      color: $neutral40;
    }
  }

  .division-header {
    padding-top: 44px;
    padding-bottom: 0;

    .title {
      padding-bottom: 18px;
    }
  }

  ul {
    @include grantsList;

    display: flex;
    flex-wrap: wrap;

    @include respond($break60) {
      margin-left: -$gridPadding;
    }

    @include respond($break100) {
      margin-left: -$gridPaddingMax;
    }

    li {
      width: 100%;
      margin-bottom: 38px;

      @include respond($break60) {
        width: calc(50% - #{$gridPadding});
        margin-left: #{$gridPadding};
      }

      @include respond($break100) {
        width: calc(50% - #{$gridPaddingMax});
        margin-left: $gridPaddingMax;
      }
    }

    + .division-header {
      padding-top: 27px;
    }
  }

  .arrow-link {
    color: $neutralOffBlack;

    &:hover {
      color: $accent;
    }
  }
}
