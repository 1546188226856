@mixin protoIcon {
  @include fontIcon;

  display: inline-block;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-transform: none;
  vertical-align: baseline;
  speak: none;
}

@mixin iconPre($icon) {
  speak: none;

  &::before {
    @include protoIcon;

    content: $icon;
  }
}

@mixin iconPost($icon) {
  speak: none;

  &::after {
    @include protoIcon;

    content: $icon;
  }
}
