.guiding-principals {
  padding-top: 40px;
  padding-bottom: 35px;

  @include respond($break50) {
    padding-top: 8.497vw;
    padding-bottom: 7.91vw;
  }

  @include respond($break100) {
    padding-top: 87px;
    padding-bottom: 81px;
  }

  .container {
    max-width: 840px;
  }

  header {
    position: relative;
  }

  // <nav> with <ul>
  .pager-numbered {
    ul {
      @include listHorizontal;

      padding-top: 8px;
      padding-bottom: 10px;

      @include respond($break50) {
        padding-top: 0;
        padding-bottom: 25px;
      }

      @include respond($break70) {
        padding-bottom: 0;
      }
    }

    li {
      vertical-align: middle;

      &:nth-child(2) {
        @include respond($break100) {
          margin-left: 0;
        }
      }

      &.active button {
        color: $accent;

        @include respond($break50) {
          color: $neutralWhite;
          background-color: $neutralOffBlack;
        }
      }

      + li {
        margin-left: 16px;

        @include respond($break80) {
          margin-left: 24px;
        }
      }
    }

    button {
      @include buttonUnstyled;

      font-size: 13px;
      line-height: 28px;

      @include respond($break50) {
        width: 28px;
        height: 28px;
        background-color: $neutralWhite;
        border-radius: 100%;
      }
    }

    .page-left,
    .page-right {
      position: static;
      display: inline-block;
      background-color: transparent;

      @include respond($break100) {
        display: none;
      }

      &::before {
        font-size: 16px;
        line-height: 28px;
      }

      + li {
        @include respond($break100) {
          margin-left: 0;
        }
      }
    }
  }

  // Figure with cycling images
  .slide-icon {
    position: relative;
    width: 55px;
    height: 55px;

    @include respond($break70) {
      position: absolute;
      top: 65%;
      right: 0;
      margin-top: -27px;
    }

    img {
      position: absolute;
      width: 100%;
      height: auto;
      opacity: 0;
      transition: opacity $duration $easeOutCubic;

      &.active {
        opacity: 1;
      }
    }
  }

  .slider-container {
    position: relative;
    margin-top: 14px;
    overflow: hidden;

    @include respond($break50) {
      margin-top: 18px;
    }

    @include respond($break70) {
      margin-top: 63px;
    }
  }

  .slider {
    @include listUnstyled;

    display: flex;
    width: 700vw;
    transition: transform $durationSlow $easeOutCubic;

    li {
      width: 100%;
      font-size: 14px;
      line-height: 1.556;

      @include respond($break50) {
        font-size: 18px;
      }

      strong {
        text-decoration: underline;
      }
    }
  }

  .page-left,
  .page-right {
    @include buttonUnstyled;

    position: absolute;
    top: 0;
    display: none;
    font-size: 42px;
    transition: margin $duration $easeOutCubic;

    @include respond($break100) {
      display: block;
    }
  }

  .page-left {
    @include iconPre($caretLeft);

    left: $containerPadding;

    @include respond($break100) {
      left: $containerPaddingMax;
    }

    &:hover {
      margin-right: 8px;
      margin-left: -8px;
    }
  }

  .page-right {
    @include iconPre($caretRight);

    right: $containerPadding;

    @include respond($break100) {
      right: $containerPaddingMax;
    }

    &:hover {
      margin-right: -8px;
      margin-left: 8px;
    }
  }
}
