// Fonts
// -----

// Do your @font-face embedding here, in the privacy of your own partial

// Do your @font-face embedding here, in the privacy of your own partial
@font-face {
  font-family: Apercu;
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/Apercu-Regular.woff') format('woff');
}

// Mixins
// --------------------------
@mixin fontBase {
  font-family: Apercu, Arial, sans-serif;
}
