// Patterns
// --------

// Basic utility patterns
// --------------------------------------------------------
// Visually Hidden, for skip links and screen-reader only elements
.screen-reader-text,
.skip-link {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  border: 0;
}

.skip-link {
  &:focus {
    top: 5px;
    left: 5px;
    z-index: 100000;
    display: block;
    width: auto;
    height: auto;
    /* stylelint-disable-next-line declaration-no-important */
    clip: auto !important;
    clip-path: none;
    outline: none;
  }

  > span {
    display: block;
  }
}

// Image replacement, for icons and things with hidden accessibility text
.ir {
  display: block;
  overflow: hidden;
  text-align: left;
  text-indent: -999em;
  background-color: transparent;
  background-repeat: no-repeat;
  border: 0;
  direction: ltr;

  br {
    display: none;
  }
}
