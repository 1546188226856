@use 'sass:color';

// Section wrapping a container and a <ul>
.featured-posts {
  // Container goes full width below a certain width
  .list-container {
    position: relative;
    overflow: hidden;

    @include respond($break80, max) {
      padding-right: 0;
      padding-left: 0;
    }
  }

  .page-left,
  .page-right {
    @include buttonUnstyled;

    position: absolute;
    top: 25.869vw;

    // Local z-index above slider
    z-index: 10;
    display: flex;
    width: 30px;
    height: 30px;
    margin-top: -15px;
    background-color: color.adjust($neutralWhite, $alpha: -0.3);
    border-radius: 100%;

    @include respond($break80) {
      display: none;
    }

    &::before {
      display: block;
      width: 0;
      height: 0;
      margin: auto;
      content: '';
      border-style: solid;
    }
  }

  .page-left {
    left: $containerPadding * 0.5;

    &::before {
      border-color: transparent $accent transparent transparent;
      border-width: 3px 5px 3px 0;
    }
  }

  .page-right {
    right: $containerPadding * 0.5;

    &::before {
      border-color: transparent transparent transparent $accent;
      border-width: 3px 0 3px 5px;
    }
  }

  ul {
    @include listUnstyled;

    display: flex;
    transition: transform $durationSlow $easeOutCubic;

    @include respond($break80) {
      flex-wrap: wrap;
      width: calc(100% + #{$gridPadding});
      margin-left: -$gridPadding;

      // Important required to normalize Javascript tranformation when used as a slider
      /* stylelint-disable-next-line declaration-no-important */
      transform: translateX(0) !important;
    }

    @include respond($break100) {
      width: calc(100% + #{$gridPaddingMax});
      margin-left: -$gridPaddingMax;
    }

    &.count-2 {
      width: 200vw;
    }

    &.count-3 {
      width: 300vw;
    }

    &.count-2,
    &.count-3 {
      @include respond($break80) {
        width: calc(100% + #{$gridPadding});
      }

      @include respond($break100) {
        width: calc(100% + #{$gridPaddingMax});
      }
    }

    &.linked {
      li:first-child {
        @include respond($break80) {
          width: calc(75% - #{$gridPadding});
        }

        @include respond($break100) {
          width: calc(75% - #{$gridPaddingMax});
        }

        // Adjusted figure/teaser widths for linked variety
        figure {
          @include respond($break80) {
            width: calc(68% - #{$gridPadding * 0.5});
          }

          @include respond($break80) {
            width: calc(68% - #{$gridPaddingMax * 0.5});
          }
        }

        figure + .teaser {
          @include respond($break80) {
            width: calc(32% - #{$gridPadding * 0.5});
          }

          @include respond($break100) {
            width: calc(32% - #{$gridPaddingMax * 0.5});
          }
        }
      }
    }
  }

  li {
    width: 100vw;

    @include respond($break80) {
      width: calc(50% - #{$gridPadding});
      margin-bottom: 4.199vw;
      margin-left: $gridPadding;
    }

    @include respond($break100) {
      width: calc(50% - #{$gridPaddingMax});
      margin-bottom: 43px;
      margin-left: $gridPaddingMax;
    }

    &:first-child {
      // Only modified over $break80
      @include respond($break80) {
        width: calc(100% - #{$gridPadding});
      }

      @include respond($break100) {
        width: calc(100% - #{$gridPaddingMax});
      }

      figure {
        @include respond($break80) {
          width: calc(75% - #{$gridPadding * 0.5});
        }

        @include respond($break100) {
          width: calc(75% - #{$gridPaddingMax * 0.5});
        }

        + .teaser {
          @include respond($break80) {
            width: calc(25% - #{$gridPadding * 0.5});
          }

          @include respond($break100) {
            width: calc(25% - #{$gridPaddingMax * 0.5});
          }
        }

        .image {
          width: 100%;

          @include respond($break80) {
            padding-top: 47.511%;
          }
        }
      }

      .teaser {
        @include respond($break80) {
          width: calc(75% - #{$gridPadding * 0.5});
        }

        @include respond($break100) {
          width: calc(75% - #{$gridPaddingMax * 0.5});
        }
      }
    }
  }

  // Mini page-nav that can be embedded in a featured list
  .page-nav {
    @include respond($break80) {
      width: calc(25% - #{$gridPadding});
    }

    @include respond($break100) {
      width: calc(25% - #{$gridPaddingMax});
    }

    &.full {
      padding-top: 55px;

      @include respond($break80) {
        display: none;
      }
    }

    &.grid {
      display: none;

      @include respond($break80) {
        display: block;
      }
    }

    .arrow-link {
      @include labelPrimary;

      display: block;
      width: 100%;
      padding-top: 2px;
      padding-bottom: 0;

      + .arrow-link {
        margin-top: 20px;
      }

      &::before {
        padding-right: 5px;
      }
    }
  }

  a {
    display: flex;
    flex-direction: column;
    color: $neutralOffBlack;

    @include respond($break80) {
      flex-direction: row;
    }

    &:hover {
      color: $accent;

      .copy {
        color: $accent;
      }

      .link-indicator {
        margin-left: 8px;
      }
    }

    figure {
      position: relative;

      @include respond($break80) {
        width: calc(50% - #{$gridPadding * 0.5});
        margin-right: $gridPadding * 0.5;
      }

      @include respond($break100) {
        width: calc(50% - #{$gridPaddingMax * 0.5});
        margin-right: $gridPaddingMax * 0.5;
      }

      + .teaser {
        @include respond($break80) {
          width: calc(50% - #{$gridPadding * 0.5});
          margin-left: $gridPadding * 0.5;
        }

        @include respond($break100) {
          width: calc(50% - #{$gridPaddingMax * 0.5});
          margin-left: $gridPaddingMax * 0.5;
        }
      }

      .image {
        padding-top: 51.737%;
        background-position: 50% 50%;
        background-size: cover;

        @include respond($break80) {
          padding-top: 85.096%;
        }
      }
    }

    .teaser {
      width: 100%;
      padding-top: 26px;
      padding-right: $containerPadding;
      padding-left: $containerPadding;
      border-top: 5px solid $accent;

      @include respond($break80) {
        padding-top: 16px;
        padding-right: 0;
        padding-left: 0;
        border-top: 1px solid $accent;
      }
    }

    .title {
      padding-bottom: 14px;
      font-size: 18px;
      font-weight: $regular;
      line-height: 1.222;

      @include respond($break80) {
        font-size: 16px;
      }

      @include respond($break100) {
        font-size: 18px;
      }
    }

    .copy {
      @include copyPrimary;

      color: $neutral40;
      transition: $duration $easeOutCubic;
    }

    .link-indicator {
      @include iconPre($arrowShortRight);

      display: inline-block;
      padding-top: 19px;
      font-size: 7px;
      transition: margin $duration $easeOutCubic;

      @include respond($break80) {
        padding-top: 7px;
      }

      &.resource,
      &.news {
        // Include a different icon
        @include iconPre($arrowBoxRight);

        font-size: 13px;
      }
    }
  }

  .arrow-link {
    display: block;
    width: 100%;
    padding-top: 2px;
    padding-bottom: 76px;
    margin-top: 48px;
    text-align: left;
    border-top: 1px solid $neutralOffBlack;

    @include respond($break80) {
      display: inline-block;
      width: calc(25% - #{$gridPadding});
      margin-top: 0;
    }

    @include respond($break100) {
      width: calc(25% - #{$gridPaddingMax});
    }

    &.full {
      margin-top: 13px;

      @include respond($break80) {
        width: 100%;
      }
    }
  }
}
