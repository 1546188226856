.post-header {
  padding-bottom: 18px;

  a {
    .title {
      color: $neutralOffBlack;
      transition: color $duration $easeOutCubic;
    }

    &:hover {
      .title {
        color: $accent;
      }
    }
  }

  .hero-link {
    color: $neutralOffBlack;

    &:hover {
      color: $accent;
    }
  }

  .title {
    @include headingTertiary;

    .post-list-aside & {
      font-size: 18px;
    }
  }

  .byline {
    @include labelPrimary;

    color: $neutral40;

    a {
      color: $neutral40;
    }
  }

  // <figure> with nested <img>
  .featured-image {
    position: relative;
    display: block;
    margin-bottom: 21px;
    line-height: 0;
    text-align: center;

    .post-list-aside & {
      padding-bottom: 14px;
    }

    .image-container {
      position: relative;
      display: inline-block;
    }

    img {
      max-width: 100%;
      height: auto;
      max-height: 350px;
    }

    figcaption {
      position: absolute;
      right: 0;
      bottom: 0;
      max-width: 100%;
      text-align: right;

      &.open {
        .featured-caption {
          max-height: 1000px;
          padding: 10px 19px;
          transition:
            max-height $durationFast - 0.1s $easeOutCubic 0.1s,
            padding $durationFast $easeOutCubic;
        }

        .caption-trigger::after {
          content: '-';
        }
      }
    }

    .caption-trigger {
      @include buttonUnstyled;
      @include labelPrimary;

      padding: 0 10px;
      color: $neutralWhite;
      background: $accent;

      &::after {
        content: '+';
      }
    }

    .featured-caption {
      @include labelPrimary;

      width: 100%;
      max-height: 0;
      padding: 0 19px;
      overflow: hidden;
      background-color: $accent30;
      transition:
        max-height $durationFast $easeOutCubic,
        padding $durationFast - 0.1s $easeOutCubic 0.1s;
    }
  }

  .featured-video {
    margin-bottom: 21px;
  }
}
