// Form Primary Components
// -----------------------
// Currently used only as sign-up form, but can be
// abstracted for other use

.form-primary {
  input[type='text'],
  input[type='email'] {
    @include inputPrimary;

    display: block;
    width: 100%;
  }

  input[type='submit'] {
    @include buttonSecondary;

    margin-top: 34px;
  }
}
