.apply-pre-footer {
  padding-top: 45px;
  padding-bottom: 31px;

  a {
    display: block;
    font-size: 28px;
    color: $neutralOffBlack;
    text-align: center;

    i {
      @include iconPre($documentPencil);

      display: inline-block;
      margin-right: 50px;
      margin-bottom: -17px;
      font-size: 81px;
      vertical-align: middle;
    }

    &:hover {
      color: $accent;

      i {
        color: $neutralOffBlack;
      }
    }
  }
}
