.resource-listing {
  header {
    a {
      color: $neutralOffBlack;

      &:hover {
        color: $accent;
      }
    }
  }

  figure {
    width: 100%;
    padding-bottom: 39px;
    text-align: center;

    img {
      display: block;
      max-width: 100%;
    }
  }

  .title {
    padding-bottom: 17px;
    font-size: 18px;
    font-weight: $semibold;
  }
}

.resources-list {
  .division-header {
    padding-top: 76px;
    padding-bottom: 30px;

    .title {
      @include headingPrimary;

      color: $accent;
    }
  }

  // Initial list
  .container > ul {
    @include listUnstyled;

    display: flex;
    flex-wrap: wrap;

    @include respond($break70) {
      margin-left: -$gridPadding;
    }

    @include respond($break100) {
      margin-left: -$gridPaddingMax;
    }

    > li {
      width: 100%;
      margin-bottom: 40px;

      @include respond($break70) {
        width: calc(33.333% - #{$gridPadding});
        margin-bottom: 45px;
        margin-left: $gridPadding;
      }

      @include respond($break100) {
        width: calc(33.333% - #{$gridPaddingMax});
        margin-left: $gridPaddingMax;
      }

      &::after {
        display: block;
        width: 50%;
        height: 1px;
        margin: 25px auto 0;
        content: '';
        background-color: $neutral40;

        @include respond($break70) {
          display: none;
        }
      }
    }
  }

  .division-separator {
    margin: 47px 0 0;
    border: 0;
    border-top: 1px solid $neutral30;
  }
}
