.site-footer {
  padding-top: 48px;
  border-top: 1px solid $neutral40;

  .footer-main {
    padding-bottom: 30px;

    @include respond($break60) {
      padding-bottom: 52px;
    }

    .container {
      display: flex;
      flex-direction: column;

      @include respond($break60) {
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }

  // Logo base styles, outside of display containers
  .logo {
    font-size: 20px;
    color: $accent;

    span {
      color: $neutral40;
    }
  }

  .footer-navigation {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .logo {
      display: none;

      @include respond($break60) {
        display: block;
      }
    }
  }

  .navigation-primary {
    @include listUnstyled;

    column-gap: 0;
    max-width: 300px;
    font-size: 16px;
    line-height: 1.75;
    column-count: 2;

    a {
      color: $neutral70;
    }
  }

  .navigation-secondary {
    @include listUnstyled;

    display: none;
    padding-top: 33px;

    @include respond($break60) {
      display: block;
    }

    a {
      font-size: 14px;
      color: $neutral40;
    }
  }

  .navigation-primary a:hover {
    color: $accent;
  }

  .masthead {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 53px;

    @include respond($break60) {
      width: 34.211%;
      padding-top: 0;
    }

    p {
      @include copyPrimary;

      color: $neutral40;

      strong {
        font-weight: $regular;
        color: $neutralOffBlack;
      }
    }

    a {
      color: $accent;
    }

    .button-primary {
      display: block;
      margin-top: 48px;
      color: $neutralWhite;
    }

    .hidden-form {
      max-height: 0;
      overflow: hidden;
      transition: max-height 0s linear $duration;

      &.open {
        max-height: 9999px;
        transition: max-height 0s linear;

        .form-primary {
          filter: blur(0);
          opacity: 1;
          transform: scale(1, 1);
        }
      }
    }

    .form-primary {
      padding-top: 43px;
      filter: blur(10px);
      opacity: 0;
      transition:
        opacity $duration - 0.1 $easeOutCubic 0.1s,
        filter $duration - 0.1 $easeOutCubic 0.1s,
        transform $duration - 0.1 $easeOutCubic 0.1s;
      transform: scale(1.07, 1.07);
    }

    .logo {
      padding-top: 63px;

      @include respond($break60) {
        display: none;
      }
    }
  }

  .colophon {
    padding: 20px 0 26px;
    font-size: 12px;
    line-height: 1.583;
    letter-spacing: 0.015em;
    background-color: $accent20;
  }

  .social-link {
    display: flex;
    gap: 3px;
    align-items: flex-end;
  }
}
