.post-pagination {
  display: flex;
  justify-content: space-between;
  padding-bottom: 70px;

  @include respond($break70) {
    padding-bottom: 100px;
  }

  .previous {
    @include iconPre($arrowShortLeft);
  }

  .next {
    @include iconPre($arrowShortRight);

    text-align: right;
  }

  // NB: Defaults on both buttons have to be declared after
  // because they overwrite defaults in the icon mixin
  .previous,
  .next {
    @include labelPrimary;

    display: inline-block;
    width: 44%;
    padding-top: 3px;
    color: $neutralOffBlack;
    border-top: 1px solid $neutralOffBlack;

    @include respond($break60) {
      font-size: 14px;
    }

    &:hover {
      color: $accent;
      border-color: $accent;
    }

    &::before {
      margin-right: 13px;
      margin-bottom: 2px; // NP
      font-size: 6px;
      vertical-align: middle;
    }
  }
}
