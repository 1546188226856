// Component Typography and Prototypes
// -----------------------------------

// Template typography classes and mixins/prototypes that can be extended into content classes.
// Typographic classes can contain some structural (not textural) design where it does not require abstraction,
// and textural classes dealing with typography (color, text-decoration, pseudo elements that modify text, etc).

// They may contain:
// Nested classes or elements with typographic/basic structural modifications only, for instance a header
// with <span> classes inside.

// These should not contain classes/prototypes to handle icon fonts. Those should be handles in a separate icons partial.

// Contents
// --------------------------
// - Utilities
// - Prototypes
// - Copy
// - Headings
// - Subheadings
// - Signage
// - Labels
// - Lists
// - Forms

// Copy
// --------------------------------------------------------
@mixin copyPrimary {
  font-size: 14px;
  font-weight: $regular;

  // 20px
  line-height: 1.357;
}

@mixin copyRead {
  font-size: 14px;
  font-weight: $regular;

  // 20px
  line-height: 1.357;

  @include respond($break60) {
    font-size: 16px;
  }
}

@mixin copyCaption {
  font-size: 12px;
  font-weight: $regular;
  text-transform: uppercase;
}

@mixin leadPrimary {
  font-size: 20px;
  font-weight: $regular;
  line-height: 1.4;
}

@mixin leadSecondary {
  font-size: 16px;
  font-weight: $regular;
  line-height: 1.563;
}

// Headings
// --------------------------------------------------------
@mixin headingPrimary {
  font-size: 18px;
  font-weight: $regular;
  line-height: 1.77;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

@mixin headingSecondary {
  font-size: 20px;
  font-weight: $regular;
  line-height: 1.292;
  letter-spacing: 0.02em;

  @include respond($break50) {
    font-size: 24px;
  }
}

.heading-secondary {
  @include headingSecondary;

  padding-bottom: 1em;
}

@mixin headingTertiary {
  font-size: 20px;
  font-weight: $regular;
  line-height: 1.1;
}

@mixin headingQuaternary {
  font-size: 16px;
  font-weight: $regular;
  line-height: 1.77;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

@mixin headingPrimaryLowercase {
  @include headingPrimary;

  line-height: 1.222;
  text-transform: none;
}

// Signage
// --------------------------------------------------------
@mixin logo {
  font-size: 20px;
  font-weight: $regular;
  line-height: 1.2;
}

// Labels
// --------------------------------------------------------
@mixin labelPrimary {
  font-size: 14px;
  font-weight: $regular;
  line-height: 2.214;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}
