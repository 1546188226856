// Variables
// ----------

// Colors
// --------------------------------------------------------
// Neutrals
$neutralWhite: #fff;
$neutral70: #3f4a4a;
$neutral50: #667070;
$neutral40: #8b9494;
$neutral30: #c7cbcc;
$neutralOffBlack: $neutral70;

// Accent Colors
$accent: #ff8b1f;
$accent30: #ffe8d2;
$accent20: #fff3e9;
$accent10: #fff9f4;

// Measurements
// --------------------------------------------------------
$containerPaddingMax: 60px;
$containerPadding: 6.25%;

// 950 + (60 * 2)
$containerWidth: 1070px;
$sectionCopyWidth: 700px;
$columnContentWidth: 600px;
$baseLineHeight: 1.4;
$verticalSpace: $baseLineHeight * 1em;
$gridPadding: 3.906vw;
$gridPaddingMax: 40px;

// Animation
// --------------------------------------------------------
$durationFast: 0.15s;
$duration: 0.3s;
$durationSlow: 0.4s;
$easeOutCubic: cubic-bezier(0.215, 0.61, 0.355, 1);

// Font Weights
// --------------------------------------------------------
// $thin: 200;
$regular: 300;
$semibold: 600;

// $bold: 700;

// Responsive breakpoints
// --------------------------------------------------------
// $break130: ;
// $break120: ;
// $break110: ;
// $containerWidth - $containerPaddingMax
$break100: 950px;

// $break90: ;
$break80: 800px;
$break70: 760px;
$break60: 600px;
$break50: 480px;

// $break40: ;
// $break30: ;
// $break20: ;
// $break10: ;
