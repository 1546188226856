.site-search {
  padding-bottom: 19px;
  transition: transform $duration $easeOutCubic;
  transform: scale(1.07, 1.07);

  .open & {
    transform: scale(1, 1);
  }

  input[type='text'] {
    width: 100%;
    margin: 12px 0 38px;
    font-size: 24px;
    color: $neutralOffBlack;
    background: none;
    border: 0;
    outline: none;

    @include respond($break70) {
      width: 60%;
      margin-top: 0;
    }

    &::placeholder {
      color: $neutralOffBlack;
    }
  }

  input[type='submit'] {
    @include buttonUnstyled;
    @include labelPrimary;

    display: block;
    font-size: 12px;
  }
}

.search.search-no-results {
  .container-column-copy {
    padding-bottom: 40px;

    @include respond($break50) {
      padding-bottom: 7.368vw;
    }

    @include respond($break100) {
      padding-bottom: 70px;
    }
  }
}
