// Component Appearance
// --------------------

// Appearance classes contain primarily texture parameters: background-color, transparency, borders, etc. as well
// as some minor, internal structural properties where abstraction isn't required (to prevent over-abstracting)

// They may contain:
// Nested classes/elements with other structural or textural properties

// Appearance blocks should not contain typographic classes nor be dependent on the font-size from the element, unless
// it is as a non-dependent, comprehensive mixin (for now), or if the font size is being used for structural reasons (like the size of an icon-font glyph)

// Contents
// --------------------------
// - Imports
// - Prototypes
// - Utilities
// - Layout
// - Type
// - Images
// - Article Lists
// - Navigation Lists
// - Buttons/Links
// - Tables
// - Forms

// Layout
// --------------------------------------------------------
.bg-accent10 {
  background-color: $accent10;
}

.bg-accent20 {
  background-color: $accent20;
}

.bg-accent30 {
  background-color: $accent30;
}

.copy-neutral40 {
  color: $neutral40;

  a {
    font-weight: $semibold;
    color: $neutralOffBlack;

    &:hover {
      color: $accent;
    }
  }
}


// Article Lists
// --------------------------------------------------------
// Structure agnostic grants list that can be used by multiple
// structured list types
// NB: This is typically a <ul>
@mixin grantsList {
  @include listUnstyled;

  li {
    margin-bottom: 64px;

    // Complex selector required to prevent sizing modals
    > .title,
    a > .title {
      padding-top: 17px;
      font-size: 18px;
      font-weight: $semibold;
      color: $neutralOffBlack;
      border-top: 1px solid $neutralOffBlack;
      transition: color $duration $easeOutCubic, border-color $duration $easeOutCubic;
    }

    > .subtitle,
    a > .subtitle {
      display: block;
      padding-top: 4px;
      font-size: 14px;
      color: $neutral40;
      transition: color $duration $easeOutCubic;

      &.dark {
        color: $neutralOffBlack;
      }

      .year {
        color: $neutralOffBlack;
      }
    }

    > a:hover {
      .title,
      .subtitle {
        color: $accent;
      }

      .title {
        border-color: $accent;
      }
    }
  }
}


// Navigation Lists
// --------------------------------------------------------
@mixin termList {
  @include listUnstyled;

  li {
    a {
      @include iconPost($arrowShortDown);
      @include labelPrimary;

      display: block;
      padding-top: 11px;
      color: $neutral40;
      border-top: 1px solid $neutral30;

      &:hover {
        color: $accent;
        border-color: $accent;
      }

      &::after {
        display: block;
        font-size: 19px;
      }
    }
  }
}


// Buttons/Links
// --------------------------------------------------------
.button-primary {
  @include buttonUnstyled;

  padding: 8px 12px;
  font-size: 14px;
  line-height: 1.5;
  color: $neutralWhite;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  background-color: $accent;

  &.wrap {
    word-wrap: break-word;
  }
}

%arrow-link {
  @include iconPre($arrowShortRight);
  @include labelPrimary;

  display: inline-block;
  color: $neutral40;

  &:hover {
    &::before {
      margin-right: 0;
      margin-left: 8px;
    }
  }

  &::before {
    padding-right: 6px;
    margin-right: 8px;
    margin-bottom: 2px; // NP
    font-size: 7px;
    vertical-align: middle;
    transition: $duration $easeOutCubic;
  }

  &.full-width {
    width: 100%;
    margin-top: 13px;
  }

  &.bordered {
    border-top: 1px solid $neutralOffBlack;

    &:hover {
      border-color: $accent;
    }
  }

  &.accent-hover {
    &:hover {
      color: $accent;

      .copy {
        color: $accent;
      }

      .link-indicator {
        margin-left: 8px;
      }
    }
  }
}

@mixin buttonSecondary {
  @include buttonUnstyled;

  padding: 11px 23px;
  color: $accent;
  text-transform: uppercase;
  border: 1px solid $accent;
}

// Default arrow copy link
.arrow-link {
  @extend %arrow-link;
}

.all-link {
  @extend %arrow-link;

  display: block;
  padding-top: 2px;
  color: $neutralOffBlack;
  border-top: 1px solid $neutralOffBlack;

  &:hover {
    color: $accent;
  }
}

.box-link {
  @include iconPost($arrowBoxRight);

  display: block;
  margin-top: 14px;
  font-size: 13px;
  color: $neutralOffBlack;

  &:hover {
    color: $accent;
  }
}

.check-link {
  @include iconPre($check);
  @include labelPrimary;

  &::before {
    margin-right: 12px;
    font-size: 9px;
  }
}


// Forms
// --------------------------------------------------------
@mixin inputPrimary {
  @include copyPrimary;

  padding: 10px 0 4px;
  color: $neutralOffBlack;
  background: transparent;
  border: 0;
  border-bottom: 1px solid $accent;
  outline: 0;
}
