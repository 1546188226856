// Component Structure
// -------------------

// Structure-only classes, with parameters for margins, padding, position, display, etc.

// They may contain:
// Nested classes/elements that have structural parameters only

// NB: Containers that are modular regardless of context should use EM units
// Structure should not be dependent on typography styles but font-size CAN be used as a structural style if necessary

// Contents
// --------------------------
// - Imports
// - Prototypes
// - Utilities
// - Layout
// - Type
// - Images
// - Article Lists
// - Navigation Lists
// - Buttons/Links
// - Tables
// - Forms


// Utility
// --------------------------------------------------------
.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.no-scroll {
  overflow: hidden;
}


// Layout
// --------------------------------------------------------
.container {
  max-width: $containerWidth;
  padding-right: $containerPadding;
  padding-left: $containerPadding;
  margin-right: auto;
  margin-left: auto;

  @include respond($break100) {
    padding-right: $containerPaddingMax;
    padding-left: $containerPaddingMax;
  }
}

.container-copy {
  max-width: $sectionCopyWidth;
}

.container-column-copy {
  max-width: 600px;
}

// Responsive container-column-copy with aside
.container-two-column {
  display: flex;

  .container-column-copy {
    width: 100%;

    @include respond($break70) {
      width: 63.158%;
    }
  }

  .container-column-aside {
    display: none;
    width: 36.842%;
    padding-left: 08.421%;

    @include respond($break70) {
      display: block;
    }
  }
}

// Used when list is not within a section content already
.container-grant-list {
  padding-top: 48px;
  padding-bottom: 80px;

  &.all-years {
    padding-bottom: 48px;
  }
}
